var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { orgEyeOff, orgEyeOn, orgSortAsc, orgSortDesc } from '@nrk/origo';
import { html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { repeat } from 'lit/directives/repeat.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { PARTICIPANTS_SAVE } from '../../events';
import { trigger } from '../../utils/events';
import { sessionStorage } from '../../utils/storage';
import BaseElement from '../base/base-element';
import LoaderInline from '../loader-inline';
import ParticipantItem from './participant-item';
const { get, set } = sessionStorage;
let ParticipantsList = 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
class ParticipantsList extends BaseElement {
    constructor() {
        super();
        this.participants = [];
        this.isSavingParticipants = false;
        this.hasChange = false;
        this.sortType = get('sortType') || 'default';
        this.sortDirection = get('sortType') || 'default';
        this.showRevealedParticipants = get('showRevealedParticipants') !== 'undefined' ? get('showRevealedParticipants') : true;
        this.setSort = this.setSort.bind(this);
        this.saveParticipants = this.saveParticipants.bind(this);
    }
    saveParticipants() {
        trigger(PARTICIPANTS_SAVE);
    }
    toggleShowRevealedParticipants() {
        this.showRevealedParticipants = !this.showRevealedParticipants;
        set('showRevealedParticipants', this.showRevealedParticipants);
        this.requestUpdate();
    }
    setSort(e /* MouseEvent */) {
        const target = e.target.closest('button');
        const sortType = target.getAttribute('js-sort-type');
        const sortDirection = target.hasAttribute('js-sort-direction')
            ? target.getAttribute('js-sort-direction')
            : this.sortDirection;
        if (sortType) {
            this.sortType = sortType;
            this.sortDirection = sortDirection;
            set('sortType', sortType);
            set('sortDirection', sortDirection);
            this.requestUpdate();
        }
    }
    getSortedParticipants() {
        // We do not want to modify this.participants (something .sort does).
        let copyParticipants = [...this.participants];
        const fewestFavorites = copyParticipants.reduce((prev, current) => {
            return current.realName || current.rescued || prev.favoriteCount < current.favoriteCount
                ? prev
                : current;
        });
        const allHaveZeroFavoriteCount = copyParticipants.every((participant) => participant.favoriteCount === 0);
        if (!this.showRevealedParticipants) {
            copyParticipants = copyParticipants.filter((cp) => !cp.realName && !cp.rescued);
        }
        if (allHaveZeroFavoriteCount === false) {
            copyParticipants.forEach((cp) => {
                if (cp.favoriteCount === fewestFavorites.favoriteCount) {
                    cp.fewestFavorites = true;
                }
            });
        }
        // Check valid sort type
        if (copyParticipants[0] && typeof copyParticipants[0][this.sortType] === 'undefined') {
            return copyParticipants;
        }
        return copyParticipants.sort((a, b) => {
            if (this.sortDirection === 'desc') {
                return b[this.sortType] > a[this.sortType] ? 1 : -1;
            }
            else if (this.sortDirection === 'asc') {
                return a[this.sortType] > b[this.sortType] ? 1 : -1;
            }
            else {
                return 0;
            }
        });
    }
    render() {
        const sortedParticipants = this.getSortedParticipants();
        const allowResetSort = this.sortType !== 'default';
        const guesses = this.participants.reduce((count, current) => {
            return count + current.guessCount;
        }, 0);
        const favorites = this.participants.reduce((count, current) => {
            return count + current.favoriteCount;
        }, 0);
        return html `
      <div>
        ${this.participants.length === 0
            ? html ` <span>Ingen artister er lagt til</span> `
            : html `
              ${this.showRevealedParticipants
                ? ''
                : html `<div
                    @click="${this.toggleShowRevealedParticipants}"
                    class="text-center underline text-red-600"
                  >
                    <strong>DU HAR SKJULT AVSLØRTE DELTAGERE</strong>
                  </div>`}
              ${allowResetSort
                ? html ` <div class="text-center text-red-600">
                    <button class="underline" js-sort-type="default" @click="${this.setSort}">
                      <strong>DU HAR PÅ SORTERING</strong>
                    </button>
                  </div>`
                : ''}
              <div
                class="px-5 mt-8  py-3 mb-1 flex items-center justify-end text-gray-800 text-sm "
              >
                <div class="w-4 h-4"></div>
                <div class="ml-4" style="width: 80px;"></div>
                <div class="flex-1 mx-4">Navn</div>
                <button
                  class="mx-4 p-0 pin-button pin-button--transparent pin-text-header-label"
                  aria-label="Sorter listen basert på antall favoritter"
                  js-sort-type="favoriteCount"
                  js-sort-direction="${this.sortDirection === 'asc' ? 'desc' : 'asc'}"
                  @click="${this.setSort}"
                >
                  <div class="flex items-center pointer-events-none" style="width: 90px;">
                    <span>Favoritter</span>
                    ${this.sortType === 'favoriteCount'
                ? this.sortDirection === 'asc'
                    ? unsafeHTML(orgSortAsc)
                    : unsafeHTML(orgSortDesc)
                : ''}
                  </div>
                </button>
                <button
                  class="mx-4 p-0 pin-button pin-button--transparent pin-text-header-label"
                  aria-label="Sorter listen basert på antall forslag"
                  js-sort-type="guessCount"
                  js-sort-direction="${this.sortDirection === 'asc' ? 'desc' : 'asc'}"
                  @click="${this.setSort}"
                >
                  <div class="flex items-center" style="width: 75px;">
                    <span>Forslag</span>
                    ${this.sortType === 'guessCount'
                ? this.sortDirection === 'asc'
                    ? unsafeHTML(orgSortAsc)
                    : unsafeHTML(orgSortDesc)
                : ''}
                  </div>
                </button>
                <button
                  class="mx-4 bg-transparent text-gray-800 outline-none cursor-pointer p-0 flex items-center"
                  style="width: 80px;"
                  @click="${this.toggleShowRevealedParticipants}"
                >
                  <span class="mr-1">Avslørt</span>
                  ${this.showRevealedParticipants ? unsafeHTML(orgEyeOn) : unsafeHTML(orgEyeOff)}
                </button>
              </div>
            `}
        <pin-dnd ?candrag="${!allowResetSort}">
          <pin-droppable></pin-droppable>
          ${repeat(sortedParticipants, (p) => p.id, (p) => {
            return html `
                <pin-draggable>
                  ${ParticipantItem(p, !allowResetSort, p.fewestFavorites)}
                </pin-draggable>
                <pin-droppable></pin-droppable>
              `;
        })}
        </pin-dnd>
        <div class="flex justify-end gap-10 mr-48">
          <span>Totalt:</span>
          <span class="w-16">${favorites}</span>
          <span>${guesses}</span>
        </div>
      </div>
      <div class="flex flex-row justify-end mt-2">
        <button
          class="pin-button pin-button--transparent"
          ?disabled="${!allowResetSort}"
          @click="${this.setSort}"
          js-sort-type="default"
        >
          Nullstill sortering
        </button>
        <button
          class="pin-button pin-button--success flex items-center gap-4"
          ?disabled="${allowResetSort || this.isSavingParticipants || !this.hasChange}"
          @click="${this.saveParticipants}"
        >
          ${this.isSavingParticipants
            ? html `
                Lagrer
                <div class="flex items-center justify-center">${LoaderInline()}</div>
              `
            : 'Lagre rekkefølge'}
        </button>
      </div>
    `;
    }
};
__decorate([
    property({ type: Array })
], ParticipantsList.prototype, "participants", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantsList.prototype, "isSavingParticipants", void 0);
__decorate([
    property({ type: Boolean })
], ParticipantsList.prototype, "hasChange", void 0);
__decorate([
    state()
], ParticipantsList.prototype, "sortType", void 0);
__decorate([
    state()
], ParticipantsList.prototype, "sortDirection", void 0);
__decorate([
    state()
], ParticipantsList.prototype, "showRevealedParticipants", void 0);
ParticipantsList = __decorate([
    customElement('pin-participants-list')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
], ParticipantsList);
